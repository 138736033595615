<template>
  <div class="terms">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

        <div class="content-header row">
          <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
              <div class="col-12">

                <div class="breadcrumb-wrapper col-12">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Dashboard</a>
                    </li>

                    <li class="breadcrumb-item active">My Policies
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <hr/>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card" v-for="policy in policies">
              <div class="card-content">
                <div class="card-body">
                  <div class="row mb-3">
                    <div class="col-2">
                      <div style="background-color:#EFF5F9;margin-top: -21px;margin-left: -21px;padding: 15px;clear: both;width: 90%;">

                        <img src="../app-assets/images/calendar.png" style="max-height: 30px;" />
                        <div style="float: right; ">
                          <span><strong>Start Date:</strong> </span> <br/>
                          <span> {{ policy.wef }}</span>
                        </div>

                      </div>

                    </div>
                    <div class="col-10">
                      <span class="float-right badge badge-success" v-if="policy.status == 'Authorised'">
                          {{ policy.status }}
                      </span>
                      <span class="float-right badge badge-warning" v-else>
                        {{ policy.status }}
                      </span>
                    </div>
                  </div>
                  <div class="row mb-5">
                    <div class="col-md-5">
                      <div >
                        <img src="../app-assets/images/car.png" style="max-height: 100px;margin-left: 70px;" />
                        <div class="float-right" style="margin-top: 20px;margin-right: 70px;">
                          <h4>Comprehensive Motor <br/>
                            Insurance</h4>
                          <span v-if="policy.vehicleDetails.length > 0 && policy.vehicleDetails[0].model"> {{ policy.vehicleDetails[0].model }} </span>,<span v-if="policy.vehicleDetails.length > 0 && policy.vehicleDetails[0].numberPlate"> {{ policy.vehicleDetails[0].numberPlate }}</span>

                          <div class="mt-4">
                            <strong>Renewal Date:</strong> &nbsp;&nbsp;&nbsp; {{ policy.wet }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4" style="border-left: 1px solid #ccc;">
                      <div  style="margin-top: 20px;margin-left: 100px;">
                        <h4>Policy Number:</h4>
                        <span>{{ policy.policyNo }}</span>
                      </div>

                    </div>
                    <div class="col-md-3" style="border-left: 1px solid #ccc;">
                      <div style="margin-top: 20px;margin-left: 50px;margin-right: 50px;">
                        <a :href="'/app/policy/'+policy.policyId" style="border:2px solid #bbe600;color:#bbe600;"  class="btn btn-lg quote-btn btn-outline-dark">  View Policy</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



  </div>
</template>

<script>

  import { mapActions } from "vuex";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'home',
    data () {
      return {
        isLoading: false,
        fullPage: true,
        policies:[

        ]
      };
    },
    components: { Loading },
    mounted(){
      this.getPoliciesData();
    },

    methods:{
      ...mapActions(["getMyPolicies"]),

      getPoliciesData(){
        let self = this;

        this.isLoading = true;

        //console.log(localStorage.getItem("email"));

        this.getMyPolicies({email: localStorage.getItem("email")}).then((policies) => {
          self.isLoading = false;

          console.log(policies);

          self.policies = policies;


        }).catch(error => {
          self.isLoading = false;
          console.log(error);
        })
      }
    }
  }
</script>

<style>






</style>
